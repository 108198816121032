import './App.css';
import './assets/chat_form.css'

import Chat from './components/Chat'

export default function App() {
  return (
    <div className='App'>
      {/* <Chat /> */}
    </div>
  );
}
